<template>
  <v-card>
    <v-card-title>
      {{ $t("Documents") }}
    </v-card-title>
    <v-card-text>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="items"
        :page.sync="page"
        :server-items-length="totalResults"
        :items-per-page.sync="itemsPerPage"
      >
        <template v-slot:top>
          <div>
            <v-btn icon @click="reloadData">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="addItem">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="generateVariable(item)">
            mdi-file-document-box-check
          </v-icon>
          <v-icon small class="mr-2" @click="generateDocument(item)">
            mdi-download
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <add-document ref="addData" @reload="reloadData" />
    <generate-variable ref="generateVariable" />
    <generate-document ref="generateDocument" />
  </v-card>
</template>
<script>
import axios from "../../plugins/axios"

import AddDocument from "./AddClientsDocument"
import GenerateDocument from "./GenerateDocument"
import GenerateVariable from "./GenerateVariable"
export default {
  components: { AddDocument, GenerateDocument, GenerateVariable },
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Path", value: "path" },
      { text: "Access", value: "access" },
      { text: "Acțiuni", value: "action", sortable: false }
    ],
    totalResults: 0,
    page: 1,
    itemsPerPage: 10,

    items: []
  }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  watch: {
    $route(to) {
      if (to.name === "Documents") this.reloadData()
    },
    page() {
      this.$log("page ", this.page)
      this.reloadData()
    },
    itemsPerPage() {
      this.$log("itemsPerPage ", this.itemsPerPage)
      if (this.page === 1) {
        this.reloadData()
      } else {
        this.page = 1
      }
    }
  },
  created() {
    this.reloadData()
  },
  methods: {
    generateVariable(item) {
      this.$refs.generateVariable.editData(item)
    },
    generateDocument(item) {
      this.$refs.generateDocument.editData(item)
    },
    editItem(item) {
      this.$refs.addData.editData(item)
    },
    addItem() {
      this.$refs.addData.addData()
    },
    deleteItem(item) {
      axios.get("app_template/" + item._id).then(response => {
        axios
          .delete("app_template/" + item._id, {
            headers: {
              "If-Match": response.data._etag
            }
          })
          .then(res => {
            if (res.status === 204) this.reloadData()
          })
      })
    },
    reloadData() {
      axios
        .get(
          'app_template?projection={"name":1,"access":1,"path":1}&max_results=' +
            this.itemsPerPage +
            "&page=" +
            this.page
        )
        .then(response => {
          // this.$log("schema reload", response)
          this.items = response.data._items
          this.totalResults = response.data._meta.total
        })
    }
  }
}
</script>
