<template>
  <v-dialog v-model="show" persistent scrollable max-width="800px">
    <!-- @dragenter="dragEnter"
      @dragleave.prevent="dragLeave"
      @dragover.prevent="dragOver"
      @drop.prevent="addDropFile" -->
    <div id="dropzone" ref="dragZone" style="visibility:hidden; opacity:0">
      <div id="textnode">
        Drop files to add data.
      </div>
    </div>
    <v-card>
      <v-system-bar color="grey lighten-2" height="32">
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <v-card-title>
        {{ $t("document") }}
      </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="title" autofocus :label="$t('name')" :rules="inputRequired" />
            </v-col>
            <v-col cols="12">
              <v-btn @click="startCam">
                start
              </v-btn>
              <v-btn @click="$refs.cam.stop()">
                stop
              </v-btn>
              <v-btn @click="captureCam">
                capture
              </v-btn>
              <ul>
                <li v-for="itm in listCamera" :key="itm.deviceId" @click="changeCamera(itm)">
                  {{ itm.label }}
                </li>
              </ul>
            </v-col>
            <v-col cols="6">
              <web-cam ref="cam" :device-id="deviceId" @cameras="listCameras" />
            </v-col>
            <v-col cols="6">
              <img :src="img" class="img-responsive" />
            </v-col>
            <v-col cols="12">
              <v-file-input ref="file" v-model="file" type="file" :label="$t('file')" :rules="inputRequiredFile" />
            </v-col>
            <v-col cols="12">
              <v-radio-group v-model="access" row>
                <v-radio :label="$t('public')" value="public" />
                <v-radio :label="$t('intern')" value="intern" />
                <v-radio :label="$t('private')" value="private" />
              </v-radio-group>
            </v-col>
            <!-- <v-col cols="12" v-for="(itm, ind) in variables" :key="ind">
              <span>{{ itm }}</span>
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
// import { WebCam } from "vue-web-cam"
import { EventBus } from "../../EventBus"

export default {
  // components: { WebCam },
  data: () => ({
    camera: null,
    img: null,
    deviceId: null,
    listCamera: [],
    variables: [],
    files: [],
    access: "public",
    file: null,
    recordId: "",
    record: {},
    etag: "",
    show: false,
    title: ""
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    inputRequiredFile() {
      const rule = []
      if (this.recordId === 0) {
        rule.push(v => !!v || this.$t("required"))
      }
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.addDragEvent()
      } else {
        this.removeDragEvent()
      }
    }
  },
  methods: {
    changeCamera(e) {
      this.$log("changeCamera ", e)
      this.camera = e
      this.deviceId = e.deviceId
      // this.$refs.cam.changeCamera(e.deviceId)
      // this.$refs.cam.start()
    },
    startCam() {
      this.$log("startCam ", this.$refs.cam)
      this.$refs.cam.start()
    },
    captureCam() {
      this.$log("startCam ")
      this.img = this.$refs.cam.capture()
    },
    listCameras(e) {
      this.$log("listCameras ", e)
      this.listCamera = e
      if (this.listCamera.length > 0) {
        this.camera = this.listCamera[0]
        this.deviceId = this.camera.deviceId
      }
    },
    addDragEvent() {
      window.addEventListener("dragenter", this.dragEnter)
      window.addEventListener("dragleave", this.dragLeave)
      window.addEventListener("dragover", this.dragOver)
      window.addEventListener("drop", this.addDropFile)
    },
    removeDragEvent() {
      window.removeEventListener("dragenter", this.dragEnter)
      window.removeEventListener("dragleave", this.dragLeave)
      window.removeEventListener("dragover", this.dragOver)
      window.removeEventListener("drop", this.addDropFile)
    },
    addDropFile(e) {
      e.preventDefault()
      this.$refs.dragZone.style.visibility = "hidden"
      this.$refs.dragZone.style.opacity = 0
      this.$refs.dragZone.style.fontSize = "42px"
      this.$log("addDropFile ", e)
      this.files = Array.from(e.dataTransfer.files)
      this.$log("this.files ", this.files)
      this.file = this.files[0]
    },
    dragEnter() {
      this.$log("dragEnter")
      this.$refs.dragZone.style.visibility = ""
      this.$refs.dragZone.style.opacity = 1
      this.$refs.dragZone.style.fontSize = "48px"
    },
    dragLeave(e) {
      e.preventDefault()
      this.$log("dragLeave")
      this.$refs.dragZone.style.visibility = "hidden"
      this.$refs.dragZone.style.opacity = 0
      this.$refs.dragZone.style.fontSize = "42px"
    },
    dragOver(e) {
      e.preventDefault()
      // this.$log("dragOver")
      this.$refs.dragZone.style.visibility = ""
      this.$refs.dragZone.style.opacity = 1
      this.$refs.dragZone.style.fontSize = "48px"
    },

    addData() {
      this.$log("addData addData ")
      this.show = true
      this.recordId = 0
      this.title = ""
      this.file = null
      this.$nextTick(() => this.$refs.dataForm.resetValidation())
    },
    editData(e) {
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      this.title = e.name
      this.access = e.access
      this.file = null
      this.$log("etag ", e)
      axios.get("app_template/" + this.recordId).then(response => {
        this.$log("app_template", response)
        this.variables = response.data.variables
        this.etag = response.data._etag
      })
    },

    saveData() {
      if (this.$refs.dataForm.validate()) {
        if (this.recordId === 0) {
          this.$log("file1 ", this.file)
          const formData = new FormData()
          formData.append("name", this.title)
          formData.append("file", this.file)
          formData.append("path", this.file.name)
          formData.append("access", this.access)
          formData.append("company", this.companyId)
          formData.append("user", this.userId)
          axios
            .post("app_template/", formData, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          const formData = new FormData()
          formData.append("name", this.title)
          if (this.file) {
            formData.append("file", this.file)
          }
          formData.append("access", this.access)
          axios
            .patch("app_template/" + this.recordId, formData, {
              headers: {
                "If-Match": this.etag
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>
<style scoped>
div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}
div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}
ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
</style>
